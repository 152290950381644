<template>
	<v-container fluid fill-height>
		<v-layout align-center justify-center>
			<v-flex xs12 sm8 md4>
				<material-card>
					<v-card>
						<Progress :error="error" :loading="loading" type="overlay"></Progress>
						<v-alert :value="info" :type="alertType">{{ info }}</v-alert>
						<template v-if="register">
						<v-alert>
							Если вы хотите зарегистрироваться, нажмите кнопку снизу.
							Если вы уже заходили на этот сайт через пароль, предлагаем в профиле привязать эту социальную сеть к вашей учётной записи.
							<v-btn outlined :to="{ name: 'login'}">Зайти с помощью пароля</v-btn>
						</v-alert>
						<v-form ref="form" v-model="valid" v-if="register">
							<v-card-text v-if="needEmail">
								<v-text-field
									prepend-icon="icon-mail"
									v-model="email"
									:rules="emailRules"
									label="Email"
									name="email"
									required
								></v-text-field>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									:disabled="!valid || loading"
									color="success"
									type="submit"
									@click="_register"
								>Зарегистрироваться</v-btn>
							</v-card-actions>
						</v-form>
						</template>
					</v-card>
				</material-card>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { Validator } from "../../mixins/Validator";

export default {
	props: ["provider"],
	mixins: [Validator],
	data: () => ({
		info: null,
		loading: true,
		alertType: "error",
		error: null,
		register: false,
		needEmail: false,
		email: null,
		valid: null
	}),
	created() {
		document.title = "Вход";
		this._login();
	},
	methods: {
		handleResponse(data, error) {
			let t = this;
			if (error && !data.error) data.error = error;
			if (data.success && data.success != true) {
				t.alertType = "success";
				t.info = data.success;
				return;
			}

			if (!data.error && data.token) {
				t.$store.commit("user/authSuccess", {
					token: data.token,
					user: data.user
				});

				t.$router.push({ name: "profile" });
			}

			if (data.error) {
				if (data.error == "redirect") {
					t.$router.push({ name: "profile" });
				}
				t.info = data.error;
				t.alertType = "error";
			}
		},
		_login() {
			let t = this;
			t.loading = true;

			window.ajax(
				"/api/oauth/" + t.provider + "?code=" + t.$route.query.code + (t.$route.query.user ? '&user=' + t.$route.query.user : ''),
				{},
				function(data, error) {
					t.loading = false;

					if (data.register) {
						t.register = true;
						t.needEmail = data.need_email;
						return;
					}

					t.handleResponse(data, error);
				}
			);
		},
		_register() {
			let t = this;
			t.loading = true;

			window.ajax(
				"/api/register-oauth/" + (t.email ? t.email : ''),
				{},
				function(data, error) {
					t.loading = false;

					t.handleResponse(data, error);
				}
			);
		}
	}
};
</script>
